import React from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';


interface CustomNumericProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  maxValue: number;
  decimalScale: number;
}

const defaultmaxValue = 9999999999.99;
const defaultDecimalScale = 2;

const NumericFormatWithDecimal = React.forwardRef<NumericFormatProps, CustomNumericProps>(
  function NumericFormatWIthDecimal(props, ref) {
    const { onChange, maxValue, decimalScale, ...other } = props;
    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        decimalSeparator="."
        valueIsNumericString
        allowNegative={false}
        decimalScale={decimalScale || defaultDecimalScale}
        isAllowed={(values) => {
          const {floatValue} = values;
          return typeof floatValue === 'undefined' || Number(floatValue) <= (maxValue || defaultmaxValue) ;
        }}
      />
    );
  },
);

export default NumericFormatWithDecimal;
